// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc
} from '../../../../common/edliy_utils';
const Boxes = {
  box1: function () {
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 8, 8,-8],keepaspectratio: true, axis:false, ticks:false, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
	brd1.options.layer['image']=15;
	brd1.options.layer['polygon']=1;
	brd1.options.layer['intersection']=5;
  brd1.suspendUpdate();
  //Make the board responsive
  makeResponsive(brd1);
  // Creat titleStyle
  placeTitle(brd1, 'Refraction of Light', '(Drag Laser Light P to Change Incident Angle.)');
    // Line l1 as an interface between two environments, green, with the index of refraction
    // n_1, and the blue, with the index of refraction n_2.\
    var l1 =placeLine(brd1, [-8,0], [8,0], 2, 'black');
    var n =placeDash(brd1, [0,-8], [0,8], 1, 'black');
    var path=placeArc(brd1,[0,0], [0, 5], [-5, 0], 1, 'black');
    var M = brd1.create('point',[-4,0],{name:'M', visible:false, fixed:true});
    var I = brd1.create('point',[0,0],{name:'I', size:1, fixed:true});
    //  l1 = brd1.create('line', [M,I]);
    var ineq1 = brd1.create('inequality', [l1], {fillColor: 'green'});
    var ineq2 = brd1.create('inequality', [l1], {inverse: true, fillColor: 'blue'});

    // Normal line n with auxiliary points N and O that allows us to determine
    // the angles of incidence (&alpha;) and refraction (&beta;), respectively
    //var n = brd1.create('perpendicular', [l1,I], {name:'n', color: 'black', dash:"2", strokeWidth:1});
    var N = brd1.create('glider',[0,4,n], {name:'N', visible:false});
    var O = brd1.create('glider',[0,-4,n], {name:'O', visible:false});
    // a light source L
  //  var L = brd1.create('point', [-3,4], {name:'L', color:'red', size:3});
    var L = placeGlider(brd1, path, -2, 3, 3, 'white', 'black');
    var angle1 = placeAngle(brd1, [0,5], [0,0], [()=>L.X(), ()=>L.Y()], 1, 'blue');
    // Position of the light source L is limited to the green environment
    /*var xL, yL;
    L.on('drag', function() {
        if(L.Y() < 0 ) {
            L.moveTo([xL,yL],0);
        }
        xL = L.X(); yL = L.Y();
    });*/

    // r1, the incident light ray
    //var r1 = brd1.create('segment', [L, I], {strokeColor:'orange', strokeWidth:4});
    var r1 =placeLine(brd1, [()=>L.X(), ()=>L.Y()], [0,0], 4, 'red');
    // Sliders to control indexes of refraction
    var n_1 =placeSlider(brd1, 3, 5, 1, 3, 3, '&mu;_1');
    var n_2 =placeSlider(brd1,-6, -5, 1, 3, 3, '&mu;_2');
    // brd1.create('slider', [[-4, -3], [-2,-3], [1, 1, 3]], {name:'n_1', snapWidth: 0.01});

    // The value of s controls the kind of refraction/reflection, if s > 1 the total reflection occurs
    // (numerically it is the absolute value of the sine of the angle of refraction)
    var s = function() { return (n_1.Value()/n_2.Value())*Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L))).toFixed(6); }

    // Two possible points through which the modified ray passes, B for the reflected ray and C for the refracted one
    var B = brd1.create('point', [
               function(){ return -L.X(); },
               function(){ return L.Y(); }
            ], {
               visible: function(){
                   return (s()>1 || Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L)))==1)? true : false;
               }, name:'R_1', face:'o', size:1, visible: false
            });
    var C = brd1.create('point', [
               function(){ return 5*(n_1.Value()/n_2.Value())*Math.sin(JXG.Math.Geometry.angle(N,I,L)); },
               function(){
                   return -5*Math.cos(Math.asin((n_1.Value()/n_2.Value()) * Math.sin(JXG.Math.Geometry.angle(N,I,L)))); }
            ], {
               visible: function(){
                   return (s()<=1 && Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L)))!=1)? true : false;
               }, name:'R_2', face:'o', size:1, visible:false
            });

    // Reflected (r2) and refracted (r3) ray
    var r2 = brd1.create('segment', [I, B], {
               visible: function(){
                   return (s()>1 || Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L)))==1)? true : false;
               }, strokeColor:'orange', strokeWidth:4, lastArrow: {type: 1, size: 3}
           });
    var r3 = brd1.create('segment', [I, C], {
               visible: function(){
                   return (s()<=1 && Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L)))!=1)? true : false;
               }, strokeColor:'red', strokeWidth:4, lastArrow: {type: 1, size: 3}
           });

    // Angles of impact (angle 1), refraction (angle2) and reflection (angle3), respectively
    //var angle1 = brd1.create('nonreflexangle',[N, I, L], {radius:1,color:'orange', fillOpacity: 0, name: '&alpha;'});
    var angle2 = brd1.create('nonreflexangle',[O,I,C], {
               visible: function(){
                   return (s()<=1 && Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L)))!=1)? true : false;
               }, radius:1, color:'orange', fillOpacity: 1, name: '&beta;'
           });
    var angle3 = brd1.create('nonreflexangle',[B,I,N], {
               visible:function(){
                   return (s()>1 || Math.abs(Math.sin(JXG.Math.Geometry.angle(N,I,L)))==1)? true : false;
               }, radius:1, color:'orange', fillOpacity: 0, name: '&beta;'
           });
    brd1.unsuspendUpdate();
    },
}
export default Boxes;
