<template>
  <div>
    <h3 ref="def">
      What is Refraction?
    </h3>
    <p>
      Refraction refers to the bending of light (change in its direction of propagation) as it moves from one transparent medium to another medium.
      Shown below is an example of refraction of light as it goes from one medium (air) to another medium (glass).
      You can notice the change in direction of propagation of light.
    </p><div align-center justify-center>
      <v-layout justify-center>
        <v-img src="/assets/refraction.png"
               max-height="350px"
               max-width="350px"
               width="400px"
        />
      </v-layout>
      <h5 style="text-align:center">
        Figure: Refraction of light as it travels from air into glass. Notice the bending of light.
      </h5>
    </div>
    </p>
    <h3 ref="cause">
      Cause of Refraction
    </h3>
    <p>
      Refraction is a consequence of the fact that as light goes from one medium to another medium with a different refractive index, both the speed and the wavelength of light change. Thus, in order to maintain the continuity of the wavefront at the interface, light has to bend and change its direction of propagation.<br>
      The extent and direction of bending (towards or away from the normal) depends on the extent to which the speed (and wavelength) change, which depends on the refractive index of the two media. The following two scenarios are possible:
      <br>
      <ul class="a">
        <li> Light moving from a medium of lower refractive index to a medium of higher refractive index: in such a case both the speed and the wavelength of light decrease. </li>
        <li> Light moving from a medium of higher refractive index to a lower refractive index: in such cases both the speed and the wavelength of light increase.</li>
      </ul>
    </p>
    <h3 ref="law">
      Law of Refraction: Snell's Law
    </h3>
    <p>
      Snell's law describes the relationship between the incident angle and the refracted angle as light passes from one medium to another. Let \(i_a\) be the incident angle (measured from the interface normal) and let \(r_a\) be the refracted angle (also measured from the interface normal). Then, according to Snell's law, the ratio of the sines of the indicent angle (\(i_a\)) and refracted angle (\(r_a\)) is same as the ratio of velocities of light in the two media, i.e.
      $$ \frac{\sin r_a}{\sin i_a} = \frac{v_2}{v_1}$$
      Now, the ratio of light velocities in the two media are related as:
      $$\frac{v_1}{v_2} = \frac{\mu_2}{\mu_1}$$
      Thus, in terms of refractive indices of the two media, the Snell's law can be written as:
      $$ \frac{\sin r_a}{\sin i_a} = \frac{\mu_1}{\mu_2}$$
      Or equivalently,
      $$ \mu_1 \sin i_a = \mu_2 \sin r_a$$
    </p>
    <br>
    <h3 ref="playgraph">
      MagicGraph | Refraction of Light
    </h3>
    <p>
      This MagicGraph offers a visually interactive module that explains refraction of light.
      Shown is a ray of light travelling from medium 1 (with refraction index =\(\mu_1\)) to medium 2 (with refraction index = \(\mu_2\)).
      The refraction indices \(\mu_1\) and \(\mu_2\) of these media can be changed by using the sliders.
    </p>
    <h5>To Get Started </h5>
    <p>
      <ul style="list-style-type:square;">
        <li> Use the two sliders to change the refractive indices of two media:\(\mu_1\) and \(\mu_2\)</li>
        <li> Drag the laser pointer to change the incident angle. Press on the laser button to turn the light on. </li>
      </ul>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'RefractionOfLight',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Refraction of Light';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is Refraction?', img:'assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Cause of Refraction', img:'assets/number-2.svg', action: () => this.goto('type')},
      {title: 'Snell\'s Law', img:'assets/number-3.svg', action: () => this.goto('calculation')},
      {title: 'MagicGraph',img:'assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
